@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");

@layer base {
  html {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  @apply px-1 outline-none border border-light-gray4 my-0;
}

:root {
  --apple-indigo: rgba(88, 86, 214);
  --apple-lightgray: rgba(142, 142, 147);
  --apple-lightgray4: rgba(209, 209, 214);
  --apple-lightgray6: rgba(242, 242, 247);
  --apple-darkgray4: rgba(58, 58, 60);
}

.ka-thead-background {
  background-color: var(--apple-lightgray6) !important;
  z-index: 1;
}

.ka-row:hover {
  cursor: pointer;
  background-color: var(--apple-lightgray6);
}
